import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postJSON } from 'api/fetch';
import { RootState } from 'initializers/types';

import { formatShift } from './YourSchedule/helpers';
import { MY_WEEK_SLICE } from './constants';
interface EmployeeResponse {
  id: number;
  full_name: string;
}

interface TradeShiftResponse {
  id: number;
  role_name: string;
  start_at: string;
  end_at: string;
  user: { full_name: string };
}

export interface Shift {
  id: number;
  unscheduled: boolean;
  owner_id: number;
  owner_type: 'Job' | 'Location';
  start_at: string;
  end_at: string;
  role_name: string | null;
}

interface Job {
  id: number;
  location_id: number;
  location_name: string;
}

export interface ShiftWithLocation extends Shift {
  location_name: string;
}

interface Trade {
  id: number;
  status: string;
  shift_id: number;
  // Add other fields as needed
}

interface MyWeekState {
  userShifts: ShiftWithLocation[];
  openShifts: ShiftWithLocation[];
  selectedTab: 'my-schedule' | 'open-shifts';
  loading: boolean;
  error: string | null;
  jobs: Job[];
  availableForCoverage: { label: string; value: number }[];
  availableForCoverageLoading: boolean;
  availableForTrade: { label: string; value: number }[];
  availableForTradeLoading: boolean;
  trades: Trade[];
  tradesLoading: boolean;
}

const initialState: MyWeekState = {
  userShifts: [],
  openShifts: [],
  selectedTab: 'my-schedule',
  loading: true,
  error: null,
  jobs: [],
  availableForCoverage: [],
  availableForCoverageLoading: false,
  availableForTrade: [],
  availableForTradeLoading: false,
  trades: [],
  tradesLoading: false,
};

export const fetchProfile = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchProfile`,
  async (params: { start_date: string; end_date: string }) => {
    const profileParams = new URLSearchParams({
      ...params,
      format: 'json',
    });

    return fetchJSON(`/profile?${profileParams}`);
  }
);

export const fetchShifts = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchShifts`,
  async (params: { start_date: string; end_date: string }) => {
    const shiftsParams = new URLSearchParams({
      ...params,
      only_future: 'true',
      format: 'json',
    });

    return fetchJSON(`/user/shifts?${shiftsParams}`);
  }
);

export const fetchAvailableForTrade = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchAvailableForTrade`,
  async (params: { shift_id: number }) => {
    const response = await fetchJSON(
      `/shifts/${params.shift_id}/for_trade.json`
    );

    return response.map((shift: TradeShiftResponse) => {
      const { date, time, roleName } = formatShift(shift);

      return {
        label: shift.user.full_name,
        subLabel: `${date} ${time} ${roleName}`,
        value: shift.id,
      };
    });
  }
);

export const fetchAvailableForCoverage = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchAvailableForCoverage`,
  async (params: { shift_id: number }) => {
    const response = await postJSON(`/jobs/available_for_cover.json`, params);

    // shape response data to be used in RequestCoverModal SelectField options
    return response.map((employee: EmployeeResponse) => ({
      label: employee.full_name,
      value: employee.id,
    }));
  }
);

export const fetchTrades = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchTrades`,
  async () => fetchJSON('/user/trades.json')
);

const addLocationToShifts = (
  shifts: Shift[],
  jobs: Job[]
): ShiftWithLocation[] =>
  shifts.map(shift => {
    const matchingJob =
      shift.owner_type === 'Job'
        ? jobs.find(job => job.id === shift.owner_id)
        : jobs.find(job => job.location_id === shift.owner_id);

    return {
      ...shift,
      location_name: matchingJob?.location_name || '',
    };
  });

const myWeekSlice = createSlice({
  name: MY_WEEK_SLICE,
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchShifts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShifts.fulfilled, (state, action) => {
        // Store raw shifts first (locations not given by shifts endpoint)
        const { user_shifts, open_shifts } = action.payload;

        // If we already have jobs, add locations
        if (state.jobs.length) {
          state.userShifts = addLocationToShifts(user_shifts, state.jobs);
          state.openShifts = addLocationToShifts(open_shifts, state.jobs);
          state.loading = false;
        } else {
          // Store shifts but keep loading true until we get locations
          state.userShifts = user_shifts.map((s: Shift) => ({
            ...s,
            location_name: '',
          }));
          state.openShifts = open_shifts.map((s: Shift) => ({
            ...s,
            location_name: '',
          }));
        }
      })
      .addCase(fetchProfile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.jobs = action.payload.jobs;

        if (state.userShifts.length || state.openShifts.length) {
          state.userShifts = addLocationToShifts(state.userShifts, state.jobs);
          state.openShifts = addLocationToShifts(state.openShifts, state.jobs);
        }
        state.loading = false; // Only set loading to false after we have locations
      })
      .addCase(fetchAvailableForCoverage.pending, state => {
        state.error = null;
        state.availableForCoverageLoading = true;
      })
      .addCase(fetchAvailableForCoverage.fulfilled, (state, action) => {
        state.availableForCoverage = action.payload;
        state.availableForCoverageLoading = false;
      })
      .addCase(fetchAvailableForCoverage.rejected, (state, action) => {
        state.error =
          action.error.message || 'Failed to fetch available employees';
        state.availableForCoverageLoading = false;
      })
      .addCase(fetchAvailableForTrade.pending, state => {
        state.error = null;
        state.availableForTradeLoading = true;
      })
      .addCase(fetchAvailableForTrade.fulfilled, (state, action) => {
        state.availableForTrade = action.payload;
        state.availableForTradeLoading = false;
      })
      .addCase(fetchAvailableForTrade.rejected, (state, action) => {
        state.error =
          action.error.message || 'Failed to fetch available for trade';
        state.availableForTradeLoading = false;
      })
      .addCase(fetchTrades.pending, state => {
        state.tradesLoading = true;
      })
      .addCase(fetchTrades.fulfilled, (state, action) => {
        state.trades = action.payload;
        state.tradesLoading = false;
      })
      .addCase(fetchTrades.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch trades';
        state.tradesLoading = false;
      });
  },
});

// Selectors
export const selectUserShifts = (state: RootState) =>
  state.get(MY_WEEK_SLICE).userShifts;

export const selectOpenShifts = (state: RootState) =>
  state.get(MY_WEEK_SLICE).openShifts;

export const selectSelectedTab = (state: RootState) =>
  state.get(MY_WEEK_SLICE).selectedTab;

export const selectShiftsLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).loading;

export const selectShiftsError = (state: RootState) =>
  state.get(MY_WEEK_SLICE).error;

export const selectAvailableForCoverage = (state: RootState) =>
  state.get(MY_WEEK_SLICE).availableForCoverage;

export const selectAvailableForCoverageLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).availableForCoverageLoading;

export const selectAvailableForTrade = (state: RootState) =>
  state.get(MY_WEEK_SLICE).availableForTrade;

export const selectAvailableForTradeLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).availableForTradeLoading;

export const selectTrades = (state: RootState) =>
  state.get(MY_WEEK_SLICE).trades;

export const { setSelectedTab } = myWeekSlice.actions;

export const { reducer } = myWeekSlice;

import { toI18n } from 'util/i18n';

export const ADP_CREDIT_TIPS_SETTINGS_MODAL_TYPE =
  'ADP_CREDIT_TIPS_SETTINGS_MODAL_TYPE';
export const APPLY_CHANGES_MODAL_TYPE = 'SETTINGS/APPLY_CHANGES_MODAL_TYPE';
export const TOAST_CONNECT_MODAL = 'SETTINGS/TOAST_CONNECT_MODAL';
export const DISCONNECT_WARNING_MODAL = 'SETTINGS/DISCONNECT_WARNING_MODAL';
export const COMPLETED_DOCUMENTS_SETTINGS_MODAL =
  'COMPLETED_DOCUMENTS_SETTINGS_MODAL';
export const LABOR_SETTINGS_HISTORY_MODAL =
  'SETTINGS/LABOR_SETTINGS_HISTORY_MODAL';
export const PAYROLL_ADMIN_CONFIRMATION_MODAL =
  'PAYROLL_ADMIN_CONFIRMATION_MODAL';

export const NAVIGATION = [
  {
    content: toI18n('settings.navigation.location'),
    nested: [
      {
        content: toI18n('settings.navigation.basic'),
        route: '/settings/location/basic_info',
        key: 'basic',
      },
      {
        content: toI18n('settings.navigation.pos'),
        route: '/settings/location/pos',
        ownerOnly: true,
        key: 'pos',
      },
      {
        content: toI18n('settings.navigation.billing'),
        route: '/settings/location/billing',
        hideSaveButton: true,
        key: 'billing',
      },
    ],
  },
  {
    content: toI18n('settings.navigation.scheduling'),
    nested: [
      {
        content: toI18n('settings.navigation.enforcement'),
        route: '/settings/scheduling/enforcement',
        canApplyToAll: true,
        key: 'enforcement',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.alerts_permissions'),
        route: '/settings/scheduling/alerts_permissions',
        canApplyToAll: true,
        key: 'alerts_permissions',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.events_trades'),
        route: '/settings/schedule/events_trades',
        canApplyToAll: true,
        key: 'events_trades',
        trackChanges: true,
      },
    ],
  },
  {
    content: toI18n('settings.navigation.time_tracking'),
    nested: [
      {
        content: toI18n('settings.navigation.time_clock_options'),
        route: '/settings/time_tracking/time_clock',
        canApplyToAll: true,
        key: 'time_clock',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.overtime'),
        route: '/settings/time_tracking/overtime',
        canApplyToAll: true,
        key: 'overtime',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.breaks'),
        route: '/settings/time_tracking/breaks',
        canApplyToAll: true,
        key: 'breaks',
        trackChanges: true,
      },
    ],
  },
  {
    content: toI18n('settings.navigation.tips'),
    nested: [
      {
        content: toI18n('settings.navigation.tip_settings'),
        route: '/settings/tip_settings',
        key: 'tip_settings',
      },
      {
        content: toI18n('settings.navigation.tip_manager'),
        route: '/settings/tip_manager',
        key: 'tip_manager',
        hideSaveButton: true,
      },
    ],
  },
  {
    content: toI18n('settings.navigation.payroll'),
    nested: [
      {
        content: toI18n('settings.navigation.payroll_settings'),
        route: '/settings/payroll',
        key: 'payroll',
        trackChanges: true,
      },
    ],
  },
  {
    content: toI18n('settings.navigation.team_management'),
    nested: [
      {
        content: toI18n('settings.navigation.time_off'),
        route: '/settings/time_off',
        canApplyToAll: true,
        key: 'time_off',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.team_messenger'),
        key: 'team_messenger',
        route: '/settings/team_messenger',
        canApplyToAll: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.team_permissions'),
        key: 'team_management',
        route: '/settings/team_management',
        canApplyToAll: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.manager_log'),
        key: 'manager_log',
        route: '/settings/manager_log',
        canApplyToAll: true,
        trackChanges: true,
      },
    ],
  },
  {
    content: toI18n('settings.navigation.account'),
    nested: [
      {
        content: toI18n('settings.navigation.profile'),
        key: 'profile',
        route: '/settings/your_account/profile',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.pins'),
        key: 'pins',
        route: '/settings/your_account/pins',
        hideSaveButton: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.notifications'),
        key: 'notifications',
        route: '/settings/your_account/notifications',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.security'),
        key: 'security',
        route: '/settings/your_account/security',
        ownerOnly: false,
        hideSaveButton: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.security_password'),
        key: 'security_password',
        route: '/settings/your_account/security_password',
        ownerOnly: false,
        hideSaveButton: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.api'),
        key: 'api',
        route: '/settings/api',
        hideSaveButton: false,
      },
    ],
  },
];

export const NAVIGATION_WITH_ALL_PAYROLL_SETTINGS = [
  {
    content: toI18n('settings.navigation.location'),
    nested: [
      {
        content: toI18n('settings.navigation.basic'),
        route: '/settings/location/basic_info',
        key: 'basic',
      },
      {
        content: toI18n('settings.navigation.pos'),
        route: '/settings/location/pos',
        ownerOnly: true,
        key: 'pos',
      },
      {
        content: toI18n('settings.navigation.billing'),
        route: '/settings/location/billing',
        hideSaveButton: true,
        key: 'billing',
      },
    ],
  },
  {
    content: toI18n('settings.navigation.scheduling'),
    nested: [
      {
        content: toI18n('settings.navigation.enforcement'),
        route: '/settings/scheduling/enforcement',
        canApplyToAll: true,
        key: 'enforcement',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.alerts_permissions'),
        route: '/settings/scheduling/alerts_permissions',
        canApplyToAll: true,
        key: 'alerts_permissions',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.events_trades'),
        route: '/settings/schedule/events_trades',
        canApplyToAll: true,
        key: 'events_trades',
        trackChanges: true,
      },
    ],
  },
  {
    content: toI18n('settings.navigation.time_tracking'),
    nested: [
      {
        content: toI18n('settings.navigation.time_clock_options'),
        route: '/settings/time_tracking/time_clock',
        canApplyToAll: true,
        key: 'time_clock',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.overtime'),
        route: '/settings/time_tracking/overtime',
        canApplyToAll: true,
        key: 'overtime',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.breaks'),
        route: '/settings/time_tracking/breaks',
        canApplyToAll: true,
        key: 'breaks',
        trackChanges: true,
      },
    ],
  },
  {
    content: toI18n('settings.navigation.tips'),
    nested: [
      {
        content: toI18n('settings.navigation.tip_settings'),
        route: '/settings/tip_settings',
        key: 'tip_settings',
      },
      {
        content: toI18n('settings.navigation.tip_manager'),
        route: '/settings/tip_manager',
        key: 'tip_manager',
        hideSaveButton: true,
      },
    ],
  },
  {
    content: toI18n('settings.navigation.payroll'),
    nested: [
      {
        content: toI18n('settings.navigation.payroll_settings'),
        route: '/settings/payroll',
        key: 'payroll',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.taxes'),
        route: '/settings/payroll/taxes',
        key: 'payroll_taxes',
        trackChanges: true,
        hideSaveButton: true,
      },
      {
        content: toI18n('settings.navigation.payroll_autopayroll'),
        route: '/settings/payroll/autopayroll',
        key: 'autopayroll',
        rolloutName: 'auto_payroll',
      },
    ],
  },
  {
    content: toI18n('settings.navigation.team_management'),
    nested: [
      {
        content: toI18n('settings.navigation.time_off'),
        route: '/settings/time_off',
        canApplyToAll: true,
        key: 'time_off',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.team_messenger'),
        key: 'team_messenger',
        route: '/settings/team_messenger',
        canApplyToAll: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.team_permissions'),
        key: 'team_management',
        route: '/settings/team_management',
        canApplyToAll: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.manager_log'),
        key: 'manager_log',
        route: '/settings/manager_log',
        canApplyToAll: true,
        trackChanges: true,
      },
    ],
  },
  {
    content: toI18n('settings.navigation.account'),
    nested: [
      {
        content: toI18n('settings.navigation.profile'),
        key: 'profile',
        route: '/settings/your_account/profile',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.pins'),
        key: 'pins',
        route: '/settings/your_account/pins',
        hideSaveButton: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.notifications'),
        key: 'notifications',
        route: '/settings/your_account/notifications',
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.security'),
        key: 'security',
        route: '/settings/your_account/security',
        ownerOnly: false,
        hideSaveButton: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.security_password'),
        key: 'security_password',
        route: '/settings/your_account/security_password',
        ownerOnly: false,
        hideSaveButton: true,
        trackChanges: true,
      },
      {
        content: toI18n('settings.navigation.api'),
        key: 'api',
        route: '/settings/api',
        hideSaveButton: false,
      },
    ],
  },
];

export const SETTINGS_CONFIGS = {};
export const SETTINGS_FORM_NAMES = [];
const NAV_ITEMS_PROPS = [];
export const ROUTE_TO_FORM_NAME_MAP = {};
export const ROUTE_TO_FORM_KEY_MAP = {};

const updateConstants = item => {
  SETTINGS_FORM_NAMES.push(item.content);
  SETTINGS_CONFIGS[item.key] = item;
  NAV_ITEMS_PROPS.push(item.key);
  ROUTE_TO_FORM_NAME_MAP[item.route] = item.content;
  ROUTE_TO_FORM_KEY_MAP[item.route] = item.key;
};

NAVIGATION_WITH_ALL_PAYROLL_SETTINGS.forEach(item => {
  if (item.route) {
    updateConstants(item);
  }
  if (item.nested) {
    item.nested.forEach(updateConstants);
  }
});

export { NAV_ITEMS_PROPS };

export const MY_SETTINGS_FORM_NAMES = ['profile', 'pins', 'notifications'];
export const VIEW_COMPLETED_DOCUMENTS = 'view_completed_documents';
export const DOCUMENT_MANAGEMENT = 'document_management';
export const GENERAL_MANAGER = 'gm';
export const INTEGRATED_PARTNER_KEYS = [
  'gusto',
  'adp_run',
  'quickbooks',
  'square',
];
export const DEFAULT_PARTNER = 'csv';

export const LEARN_MORE_LINK =
  'https://support.joinhomebase.com/s/article/Tip-Credits';

export const MINIMUM_WAGE_RATE = 7.25;

export const SETTINGS_ROUTES = {
  LOCATION: {
    BASIC_INFO: '/settings/location/basic_info',
    POS: '/settings/location/pos',
    BILLING: '/settings/location/billing',
    CHANGE_OWNERSHIP: '/settings/location/change_ownership',
  },
  TIME_TRACKING: {
    TIME_CLOCK: '/settings/time_tracking/time_clock',
    OVERTIME: '/settings/time_tracking/overtime',
    BREAKS: '/settings/time_tracking/breaks',
  },
  SCHEDULING: {
    ENFORCEMENT: '/settings/scheduling/enforcement',
    ALERTS_PERMISSIONS: '/settings/scheduling/alerts_permissions',
    EVENTS_TRADES: '/settings/schedule/events_trades',
  },
  YOUR_ACCOUNT: {
    PROFILE: '/settings/your_account/profile',
    PINS: '/settings/your_account/pins',
    NOTIFICATIONS: '/settings/your_account/notifications',
    SECURITY_PASSWORD: '/settings/your_account/security_password',
  },
  TIP_MANAGER: {
    TIP_SETTINGS: '/settings/tip_settings',
    TIP_MANAGER: '/settings/tip_manager',
  },
  PAYROLL: {
    SETTINGS: '/settings/payroll',
    TAXES: '/settings/payroll/taxes',
    AUTO_PAYROLL_SETTINGS: '/settings/payroll/autopayroll',
  },
  TIME_OFF: '/settings/time_off',
  TEAM_MESSENGER: '/settings/team_messenger',
  TEAM_MANAGEMENT: '/settings/team_management',
  MANAGER_LOG: '/settings/manager_log',
  API: '/settings/api',
};

export const GROUP_TYPES = {
  MANAGER: 'manager',
  EMPLOYEE: 'employee',
  GENERAL: 'general',
  EMPLOYEE_PERMISSIONS: 'employee_permissions',
  UNPAID_BREAKS: 'unpaid_breaks',
  MISSED_BREAKS: 'missed_breaks',
  MANAGE_BREAKS: 'manage_breaks',
};

export const GROUP_NAMES = {
  [GROUP_TYPES.MANAGER]: toI18n('settings_refresh.group_labels.manager'),
  [GROUP_TYPES.EMPLOYEE]: toI18n('settings_refresh.group_labels.employee'),
  [GROUP_TYPES.GENERAL]: toI18n('settings_refresh.group_labels.general'),
  [GROUP_TYPES.EMPLOYEE_PERMISSIONS]: toI18n(
    'settings_refresh.group_labels.employee_permissions'
  ),
  [GROUP_TYPES.UNPAID_BREAKS]: toI18n(
    'settings_refresh.group_labels.unpaid_breaks'
  ),
  [GROUP_TYPES.MISSED_BREAKS]: toI18n(
    'settings_refresh.group_labels.missed_breaks'
  ),
  [GROUP_TYPES.MANAGE_BREAKS]: toI18n(
    'settings_refresh.group_labels.manage_breaks'
  ),
};

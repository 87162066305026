export const MY_WEEK_SLICE = 'myWeek' as const;
import { toI18n } from 'util/i18n';

export const SHIFT_TAB_OPTIONS = [
  {
    value: 'my-schedule',
    label: toI18n('dashboard.user.schedule_section.my_schedule'),
  },
  {
    value: 'open-shifts',
    label: toI18n('dashboard.user.schedule_section.open_shifts'),
  },
];
